import React, { useState } from 'react';
import { auth } from '../utils/FirebaseConfig'; // Import the Firebase auth
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // For navigation
import { checkValidData } from '../utils/ValidateForm';
import { errorIcon } from '../utils/constants';


const Auth = ({ onAuthSuccess }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false); // Toggle between Login/Signup
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use React Router's useNavigate for redirecting

  // Handle signup or login based on the form type
  const handleAuth = async (e) => {

    e.preventDefault();
    let message = checkValidData(email, password)
    if (message) {
      setError(message);
      return;
    }



    try {
      if (isSignUp) {
        if (name.length <= 3) {
          setError('Please enter a valid name')
          return;
        }

        // Signup with Firebase Auth
        createUserWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            const user = userCredential.user

            updateProfile(user, {
              displayName: name
            })
            // alert('Signup successful');
            onAuthSuccess(user); // Pass the user data back to parent
            navigate('/youtube-link'); // Navigate to YouTube link page
          })

          .catch(error => {
            console.log(error)
            setError('Email already registered')
          })

      }
      if (!isSignUp) {
        // Login with Firebase Auth
        signInWithEmailAndPassword(auth, email, password)
          .then(userCredential => {
            // alert('Login successful');
            onAuthSuccess(userCredential.user); // Pass the user data back to parent
            navigate('/youtube-link'); // Navigate to YouTube link page
          })
          .catch(error => {
            setError('Invalid Credentials')
          })

      }
    } catch (error) {
      // alert('Error: ' + error.message);
      setError(error.message);
    }
  };

  // Handle Google sign-in
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // alert('Google Sign-In successful');
      onAuthSuccess(user); // Pass the user data back to parent
      navigate('/youtube-link'); // Navigate to YouTube link page
    } catch (error) {
      // alert('Error: ' + error.message);s
      // setError(error.message);
      console.log('Error: ' + error.message);
    }
  };

  //Handle Password reset
  const handleReset = () => {
    navigate('/password-reset')
  }


  const handleToggleLogin = () => {
    setError(null);
    setIsSignUp(!isSignUp);
  }

  return (
    <div className="flex flex-col items-center justify-center h-full bg-slate-600">
      <h1 className="text-4xl font-bold px-4 my-8 text-white text-center">Welcome to <span className='text-[#D88B0F]'>YouNedia</span> </h1>
      <p className="underline text-xl mb-6">PROMOTE YOUR VIDEO TO MILLIONS</p>
      <p className="text-lg mb-6 w-full text-center px-10">Get more YouTube views on your videos. Sign up now and get seen.</p>

      <form onSubmit={handleAuth}
        className=" bg-gray-800 bg-opacity-85 w-[250px] sm:w-[338px] text-white p-6 px-10 rounded shadow-2xl">

        <h1 className="text-[30px] w-full -ml-2 text-center font-semibold mb-4 text-[#D88B0F]">{isSignUp ? 'Sign Up' : 'Login'}</h1>
        {isSignUp
          &&
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="block focus:outline focus:outline-[#D88B0F] border border-gray-600 focus:border-transparent w-full p-[10px] pl-[14px] mb-2 text-white rounded opacity-70 bg-[#1A1F27] "
          />
        }
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block focus:outline focus:outline-[#D88B0F] border border-gray-600 focus:border-transparent w-full p-[10px] pl-[14px] mb-2 text-white rounded opacity-70 bg-[#1A1F27] "
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="block  p-[10px] border w-full mb-1 pl-[14px] text-white border-gray-600 focus:border-transparent rounded opacity-70 bg-[#1A1F27]  focus:outline focus:outline-[#D88B0F]"
        />
        {
          error ?
            <div className=' flex items-center mb-3 mt-[2px] ml-1'>
              {errorIcon}
              <p className='text-red-600 ml-1 text-[13px]'>{error}</p></div>
            :
            <p className='mb-3'></p>
        }
        <button type="submit" className="bg-gradient-to-b  from-[#2366e1] to-[#203f78] hover:opacity-85  text-white font-semibold py-2 px-4 rounded w-full">
          {isSignUp ? 'Sign Up' : 'Login'}
        </button>

        {!isSignUp &&
          <div className='w-full flex justify-center'>
            <p className='text-white mt-2 text-center text-[13px] cursor-pointer hover:underline' onClick={handleReset}>Forgot Password?</p>
          </div>}

        <div>

          <p className='  w-full text-center my-3 font-semibold text-xl'>OR</p>

        </div>
        <button
          type="button"
          onClick={handleGoogleSignIn}
          className="hover:opacity-80 text-white my-2 mx-2 sm:mx-8 rounded w-fit"
        >
          <img src={require("../images/google.png")} className='bg-white rounded' width="195" alt="Google" />
        </button>
        <div className="mt-4 ml-5 w-fit   text-white  hover:opacity-90" >
          {isSignUp ? <p>Already a member? <span onClick={handleToggleLogin} className='cursor-pointer text-[#D88B0F] font-semibold '>Login</span></p> : <p>New to YouNedia? <span onClick={handleToggleLogin} className='cursor-pointer font-semibold text-[#D88B0F]'>SignUp</span></p>}
        </div>

      </form>
      <p className="bold-100 my-6 text-gray-900 text-center px-4 text-black-700 text-sm"><span className=' underline '>Here’s how it works:</span> Sign up, paste your YouTube link, set your budget, and we’ll handle the rest. Your video will be promoted to your target audience, helping you gain more visibility and engagement.</p>

    </div>
  );
};
export default Auth;
