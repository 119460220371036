import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Auth from './components/Auth';
import YouTubeForm from './components/YouTubeForm';
import Header from './components/Header';
import ForgotPassword from './components/ForgotPassword';

function App() {
  const [user, setUser] = useState(null); // Store the authenticated user

  // Callback to handle successful authentication
  const handleAuthSuccess = (authenticatedUser) => {
    console.log(authenticatedUser)
    setUser(authenticatedUser); // Set the user once authenticated
  }; 

  // Callback to handle sign-out
  const handleSignOut = () => {
    setUser(null); // Set user to null after sign-out
  };

  return (
    <Router>
      <Header user={user} onSignOut={handleSignOut} setUser={setUser}/>
      <Routes>
        {/* Route for authentication */}
        <Route
          path="/"
          element={user ? <Navigate to="/youtube-link" /> : <Auth onAuthSuccess={handleAuthSuccess} />}
        />
        
        {/* Route for YouTube form, only accessible if user is authenticated */}
        <Route
          path="/youtube-link"
          element={user ? <YouTubeForm user={user} /> : <Navigate to="/" />}
        />

        <Route
          path='/password-reset'
          element={<ForgotPassword/>}
        />
      </Routes>
    </Router>
  );
}
export default App;
