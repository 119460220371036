
import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react'
import { auth } from '../utils/FirebaseConfig';
import { useNavigate } from 'react-router-dom';
import { errorIcon, leftArrow, mailIcon } from '../utils/constants';

const ForgotPassword = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email).then(data => {
            setError('')
            setSuccess('Email sent Successfully!')
            // alert('Check your email')
        }).catch(err => {
            setSuccess('')
            setError('Please enter a valid mail')
            // alert(err)
        })
    }

    //Revert back to login page 
    const backToLogin = () => {
        navigate('/');
    }

    return (
        <div className='h-[100vh] bg-slate-600 flex items-center justify-center w-full'>

            <div className='bg-gray-800 p-6 rounded-3xl flex gap-10 items-center justify-center'>

                <img className='w-[370px] ' src={require('../images/forgotPassword.png')} alt='forgotPassword' />

                <div className='text-center h-[370px]'>
                    <h1 className='font-bold text-3xl mt-16 mb-2 text-white'>Forgot <span className='text-[#D88B0F]'>Password</span> </h1>

                    <p className='text-gray-300 text-sm mb-6 text-center w-[350px] '>Enter your email and we'll send you a link to reset your password.</p>

                    <form onSubmit={handleSubmit} >

                        <div className='relative'>
                            <span className='text-white absolute top-[16px] left-3 w-10'>{mailIcon}</span>
                            
                            <input
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block  pl-10 border w-full p-[10px] mb-2 text-white rounded opacity-70 bg-[#454f60] focus:outline focus:outline-[#D88B0F] border-none  "
                            />
                        </div>
                        {
                            success &&
                                <div className=' flex items-center mb-3 ml-1'>
                                   <p className='text-green-400 ml-1 text-[13px]'>{success}</p></div>
                        }
                        {
                            error ?
                                <div className=' flex items-center mb-3 ml-1'>
                                    {errorIcon}
                                    <p className='text-red-600 ml-1 text-[13px]'>{error}</p></div>
                                :
                                <p className='mb-3'></p>
                        }

                        <button type='submit' className='bg-gradient-to-b  from-[#2366e1] to-[#203f78] hover:opacity-85  text-white font-semibold py-2 px-4 rounded w-full'>Submit</button>
                    </form>

                    <div className='w-full flex justify-center'>
                        <p className='cursor-pointer w-fit  flex items-center gap-[5px] hover:scale-95 text-[#D88B0F] text-sm mt-6' onClick={backToLogin}><span className='mt-[2px]'>{leftArrow}</span> Back to Login</p>

                    </div>
                </div>
 
            </div>



        </div>
    )
}

export default ForgotPassword