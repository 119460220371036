/* eslint-disable react-hooks/exhaustive-deps */
import { auth } from '../utils/FirebaseConfig'; // Import your Firebase config
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // For navigation
import logo from '../images/younedia.png';
import { useEffect, useState } from 'react';


const Header = ({ user, onSignOut, setUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Use React Routser's useNavigate for redirecting


  useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth, (res)=>{
      if(res){
        setUser(res);
        navigate('/youtube-link')
      }
      else{
        setUser(null)
        navigate('/')
      }
    })

    return () =>unsubscribe();
  },[])

  const handleSignOut = async () => {
    try {
      await signOut(auth); // Sign out the user from Firebase
      setIsOpen(false)
      onSignOut(); // Set user to null in App.js
      navigate('/'); // Redirect to the login page
    } catch (error) {
      console.error('Sign Out Error', error);
    }
  };

  return (
    <header className="px-6 py-3 bg-gray-800 text-white flex justify-between items-center">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-14" />
        <h1 className="ml-4 text-[#D88B0F] text-3xl font-[700]">YouTube <span className='text-white'>Promotion</span></h1>
      </div>
      <div className="flex items-center">
        {user ? (
          <div className="flex items-center " onMouseOver={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>

            <div className='relative border border-gray-500 hover:border-[#D88B0F]  p-2 rounded-lg pr-0 cursor-pointer'>
              <div className='flex gap-2 mr-3 items-center' >
                {!user.photoURL ?
                  (<img
                    src={require('../images/userIcon.jpg')}
                    alt='user'
                    className="w-7 h-7 rounded-full ml-2"
                  />)
                  : (
                    <img
                      src={user.photoURL} 
                      alt={user.displayName || 'Profile'}
                      className="w-7 h-7 rounded-full ml-2"
                    />
                  )}
                <span className='font-semibold text-lg '>{user.displayName || 'User'}</span>
                <img className='h-[8px] mt-1 mr-1' src={require('../images/down-arrow.png')} alt=''/>
              </div>

              {
                isOpen
                &&
                <ul className=' bg-gray-700 w-[95%] bg-opacity-90 text-center rounded absolute right-0 top-[45px] shadow-lg'>
                  <li className='hover:text-[#D88B0F] hover:bg-black after:block after:h-[1px] after:mt-1 after:bg-black text-white text-[14px]  my-1 mt-3 py-[3px] px-6   cursor-pointer'><a href='https://www.younedia.com/contact-us' rel='noreferrer' target='_blank'>Contact Us</a> </li>
                  <li className='hover:text-[#D88B0F] hover:bg-black after:block after:h-[1px] after:mt-1 after:bg-black text-white text-[14px]  my-1 py-[3px] px-6   cursor-pointer'><a href='https://www.younedia.com/about-us' rel='noreferrer' target='_blank'>About Us</a> </li>
                  <li className='hover:text-red-700 hover:bg-black text-white text-[14px] my-1 mb-3 py-[3px] px-6  cursor-pointer' onClick={handleSignOut}>Logout</li>
                </ul>
              }
            </div>
            {/* <button 
              onClick={handleSignOut}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            >
              Sign Out
            </button> */}


          </div>
        ) : (
          <p></p>
        )}
      </div>
    </header>
  );
};
export default Header;
